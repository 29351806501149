/**
 * https://dev.to/paulriviera/add-azure-application-insights-to-a-static-nextjs-app-921
 */

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AppInsightsContext,
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: process.env.NEXT_PUBLIC_APP_INSIGHTS_CONNECTIONSTRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});

ai.loadAppInsights();

// eslint-disable-next-line
const telemetryInitializer = (envelope: any) => {
  // eslint-disable-next-line
  envelope.tags['ai.cloud.role'] = 'ConferencesUI';
};

ai.addTelemetryInitializer(telemetryInitializer);

const AzureAppInsights = ({ children }: { children: React.ReactNode }) => (
  <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
);

const WithAppInsightsTracking = withAITracking(reactPlugin, AzureAppInsights);

export { WithAppInsightsTracking as ApplicationInsights, ai as default };
